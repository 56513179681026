@import 'styles/colors.scss';

:root {
    @include color-variables;
}

* {
    box-sizing: border-box;
}

html {
    overflow-x: hidden;
}

html,
body {
    margin: 0;
    padding: 0;
}

body {
    color: $color-text;
    font-family: 'Montserrat', Verdana, sans-serif;
    font-size: 15px;
    line-height: 19px;
    font-weight: normal;
    letter-spacing: 0.01em;
    display: block;
    background-color: $color-background;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-print-color-adjust: exact !important;
}

a {
    text-decoration: none;
    font-weight: 600;
    color: $color-text;
    font-family: 'Montserrat';
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 10px 0;
    font-family: 'Open Sans';
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-weight: 600;
    margin-top: 0;
    letter-spacing: -0.5px;
    position: relative;
    text-align: center;

    page-break-after: avoid;
    break-after: avoid;

    & + p {
        margin-top: 0;
    }
}

h1 {
    font-size: 44px;
    line-height: 55px;

    &::before {
        top: 4px;
        bottom: 8px;
    }
}

h2 {
    font-size: 31px;
    line-height: 40px;
}

h3 {
    font-size: 27px;
    line-height: 35px;
}

h4 {
    font-size: 22px;
    line-height: 31.88px;
}

h5 {
    font-size: 18px;
    line-height: 25.88px;
}

.center {
    text-align: center;
}

hr {
    border: unset;
    border-top: 1px solid #e6e6e6;
    margin: 15px 0;
}

i,
em {
    letter-spacing: -0.5px;
}

b {
    font-family: 'Montserrat';
    letter-spacing: -0.3px;
}

ul,
ol {
    padding-left: 32px;
}

li:not(:first-child) {
    margin-top: 4px;
}

ol {
    counter-reset: li-counter;

    li {
        position: relative;

        &:not(:first-child) {
            margin-top: 8px;
        }

        &::before {
            position: absolute;
            left: -28px;
            width: 24px;
            height: 24px;
            text-align: center;
            line-height: 24px;
            top: -2px;
            color: #fff;
            background-color: var(--color-primary);
            content: counter(li-counter);
            counter-increment: li-counter;
            cursor: default;
            border-radius: 50%;
            transition: all 0.5s ease;
        }
    }
}

img {
    width: 100%;
    height: auto;
}
