@import 'styles/media.scss';
@import 'styles/colors.scss';

.wrapper {
    --width: 700px;
    --sidebarWidth: 250px;
    --spacing: 20px;
    --minPadding: 20px;

    --left: Max(
        0px,
        50% - (var(--width) / 2 - (var(--sidebarWidth) + var(--spacing)) / 2)
    );

    --right: Max(
        0px,
        50% - (var(--width) / 2 + (var(--sidebarWidth) + var(--spacing)) / 2)
    );

    --sidebarLeft: Max(
        0px,
        50% - (var(--width) / 2 + (var(--sidebarWidth) + var(--spacing)) / 2)
    );

    --centered: Max(var(--minPadding), 50% - (var(--width) / 2));

    @include media-breakpoint-up(sm) {
        --minPadding: 40px;
    }

    @include media-breakpoint-down(md) {
        --left: var(--centered);
        --right: var(--centered);
    }

    @include media-breakpoint-up(xl) {
        --spacing: 60px;
        --left: Max(
            var(--minPadding) + var(--sidebarWidth),
            50% - (var(--width) / 2)
        );
        --right: calc(100% - var(--width) - var(--left));

        --sidebarLeft: Max(
            20px,
            50% - (var(--width) / 2 + var(--sidebarWidth) + var(--spacing))
        );
    }

    &:not(.showTableOfContents) {
        --left: var(--centered);
        --right: var(--centered);

        .tableOfContent {
            display: none;
        }
    }

    max-width: 100%;
    text-align: justify;

    .content {
        & > * {
            padding-left: var(--left);
            padding-right: var(--right);
        }

        & > blockquote {
            position: relative;
            color: $color-text-light;
            background-color: $color-dark-background;
            padding-bottom: 5px;
            margin: 0;

            a {
                color: $color-text-light;
            }

            & + blockquote {
                margin-top: -20px;
            }

            &::before {
                content: '';
                position: absolute;
                z-index: -1;
                top: -12px;
                bottom: -12px;
                left: 0;
                right: 0;
                background-color: $color-dark-background;
            }
        }

        & > blockquote + :not(blockquote) {
            margin-top: 30px;
        }

        & > :not(blockquote) + blockquote {
            margin-top: 30px;
            padding-top: 10px;
        }

        table {
            width: 100%;
            table-layout: fixed;
        }

        & > hr {
            margin-left: var(--left);
            margin-right: var(--right);
        }
    }

    &.showTableOfContents .tableOfContent {
        @include media-breakpoint-down(md) {
            display: none;
        }

        a {
            color: white;
        }

        position: fixed;
        top: 0;
        bottom: 0;
        left: var(--sidebarLeft);
        width: 250px;
        z-index: 1;
        padding: 12px;

        .table {
            position: absolute;
            top: 155px;

            .tableItem {
                cursor: pointer;
                margin-bottom: 8px;
            }

            .current {
                background-color: $color-primary;
                bottom: 3px;
            }
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: $color-light-background;
            opacity: 0.8;
            z-index: -1;
        }
    }
}
