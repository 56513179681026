@import 'styles/media.scss';
@import 'styles/colors.scss';

.content {
    padding: 20px 0;

    a {
        z-index: 1;
        text-decoration: none;
        font-weight: 600;
        color: inherit;
        white-space: nowrap;
        position: relative;
        font-family: 'Montserrat';
        transition: color 0.1s linear 0s, background-color 0.1s linear 0s,
            opacity 0.2s linear 0s;

        &::before {
            content: '';
            position: absolute;
            bottom: -1px;
            left: 0;
            right: 0;
            height: 3px;
            z-index: -1;
            background-color: var(--color-primary);
            transition: height 0.1s linear 0s, bottom 0.1s linear 0s;
        }

        &:hover::before {
            height: 12px;
            bottom: 3px;
        }
    }
}

.top {
    background-color: $color-light-background;
    border-bottom: 2px solid $color-background;
    position: sticky;
    display: flex;
    z-index: 2;
    top: 0;
    text-align: center;
    justify-content: center;

    @media print {
        display: none;
    }
}

.logoWrapper {
    display: inline-flex;
    align-items: center;
    height: 50px;

    @include media-breakpoint-down(md) {
        flex-grow: 1;
    }

    @include media-breakpoint-up(lg) {
        position: absolute;
        left: 0;
        width: 100px;
    }

    .logo {
        --logo-color-background: var(--color-light-background);
        --logo-color-fill: var(--color-text);

        width: 40px;
        height: 40px;
        flex-grow: 1;

        @include media-breakpoint-down(xs) {
            margin-left: -15px;
        }

        &,
        * {
            transition: fill 0.25s linear 0s;
        }
    }

    &:hover .logo {
        --logo-color-background: var(--color-background);
    }
}

.topMenuLink,
.navigationLink {
    opacity: 0.7;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 4px;

    @include media-breakpoint-up(lg) {
        padding: 0 12px;
    }

    @include media-breakpoint-down(md) {
        flex-grow: 1;
    }

    @include media-breakpoint-down(sm) {
        font-size: 14px;
    }

    @include media-breakpoint-down(xs) {
        flex-basis: 1%;
    }

    &:hover {
        opacity: 1;
    }

    &.active {
        opacity: 1;
    }
}

.topMenuLink {
    height: 50px;

    &.active {
        color: white;
        background-color: $color-primary;
    }
}

.navigationLink {
    height: 30px;
    color: white;

    @include media-breakpoint-up(md) {
        padding: 0 12px;
    }

    &.active {
        color: white;
        background-color: $color-dark-background;
    }
}

.navigation {
    background-color: $color-dark-background;
    border-bottom: 2px solid $color-background;
    margin-top: -2px;
    position: sticky;
    z-index: 2;
    top: 50px;
    text-align: center;
    justify-content: center;

    @media print {
        display: none;
    }
}
