@mixin color-variables {
    --color-text: #1b1e27;
    --color-text-light: #e8ebf4;

    --color-background: #f1ece8;

    --color-primary: #eab652;

    --color-dark-background: #778260;
    --color-light-background: #e0dad4;

    @media print {
        --color-background: white;
    }
}

$color-text: var(--color-text);
$color-text-light: var(--color-text-light);

$color-background: var(--color-background);

$color-primary: var(--color-primary);

$color-dark-background: var(--color-dark-background);
$color-light-background: var(--color-light-background);
