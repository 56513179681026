.logo {
    --logo-color-background: var(--color-background);
    --logo-color-fill: var(--color-text);
    --logo-color-colored: var(--color-dark-background);

    fill: var(--logo-color-fill);
}

.light {
    fill: var(--logo-color-background);
}

.color {
    fill: var(--logo-color-colored);
}

.small {
    height: 50px;
}

.medium {
    height: 100px;
}
